import { useState } from "react";
import PropTypes from "prop-types";
import ExecutionRow from "./ExecutionRow";
import Paginator from "./Paginator";
import { ClipLoader } from "react-spinners";
import TableFilter from "./TableFilter";
import BulkDeleteButton from "./BulkDeleteButton";
import BulkDownloadButton2 from "./BulkDownloadButton";

const ExecutionsTable = props => {

  const { executions, page, setPage, pageSize, totalCount, onFilter, loading, showHeader, noMessage, onBulkDelete } = props;

  const [selectedExecutions, setSelectedExecutions] = useState([]);

  const executionsChecked = e => {
    const ids = selectedExecutions.map(e => e.id);
    if (ids.includes(e.id)) {
      setSelectedExecutions(selectedExecutions.filter(s => s.id !== e.id))
    } else {
      setSelectedExecutions([...selectedExecutions, e])
    }
  }

  const selectedOnPage = executions.filter(e => selectedExecutions.map(s => s.id).includes(e.id));
  const anySelected = selectedOnPage.length > 0;

  const totalPages = Math.ceil(totalCount / pageSize);

  const thClass = "font-medium text-sm text-flow-gray-7 bg-[#E7E9F0] bg-opacity-80 whitespace-nowrap py-1.5 text-left px-4";

  return (
    <div className="relative" style={{minHeight: 250}}>
      <div className="flex flex-wrap gap-2 justify-between items-center mb-3">
        <div className="flex flex-wrap gap-2 items-center">
          {onFilter && <TableFilter filter={onFilter} />}
          {totalPages > 1 && (
            <Paginator
              currentPage={page} totalPages={totalPages} onChange={setPage}
            />
          )}
        </div>
        {totalCount > 0 && (
          <div className="flex flex-wrap gap-2 items-center">
            {executions.length > 0 && (
              <div
                className="text-xs link w-fit"
                onClick={() => setSelectedExecutions(anySelected ? [] : [...selectedExecutions, ...executions])}
              >
                {anySelected ? "Deselect All" : "Select All"}
              </div>
            )}
            <div className="flex flex-wrap gap-2 items-center">
              <BulkDownloadButton2 selectedIds={selectedExecutions.map(e => e.id)} isExecutions={true} />
              {onBulkDelete && (
                <BulkDeleteButton
                  selectedIds={selectedExecutions.map(e => e.id)}
                  resume={selectedExecutions.some(e => e.retries || e.retried_by)}
                  onDelete={onBulkDelete}
                  setSelectedIds={setSelectedExecutions}
                  isExecutions={true}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {executions.length === 0 && !loading && (
        <div className="mt-8 italic text-sm">
          <span className="info border-l-red-200">{noMessage}</span>
        </div>
      )}
      {loading && (
        <div className="absolute z-40 h-48 w-full flex justify-center items-center">
          <ClipLoader size={100} speedMultiplier={2} />
        </div> 
      )}
      <div className="overflow-y-auto rounded shadow w-full relative">
        <table className={`border-collapse w-full border-0 overflow-hidden ${loading ? "opacity-50 pointer-events-none" : ""}`}>
          {showHeader && executions && executions.length > 0 &&(
            <thead>
              <tr>
                <th className={thClass} />
                <th className={thClass}>Status</th>
                <th className={thClass}>Pipeline</th>
                <th className={thClass}>Identifier</th>
                <th className={thClass}>Created</th>
                {executions && executions.some(s => s.owner_name) && <th className={thClass}>Owner</th>}
                <th className={thClass}>Organism</th>
                <th className={thClass}>Sample</th>
              </tr>
            </thead> 
          )}
          <tbody>
            {executions.map((ex, index) => (
              <ExecutionRow
                execution={ex} key={ex.id} index={index}
                checked={selectedExecutions.map(s => s.id).includes(ex.id)}
                onCheck={() => executionsChecked(ex)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

ExecutionsTable.propTypes = {
  executions: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onFilter: PropTypes.func,
  loading: PropTypes.bool,
  noMessage: PropTypes.string.isRequired,
  onBulkDelete: PropTypes.func,
  showHeader: PropTypes.bool
};

export default ExecutionsTable;