import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CopyIcon } from "../images/copy.svg";

const ExecutionCommand = props => {

  const { command } = props;

  const onClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(command);
    } else {
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = command;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    }
  }

  const lines = [];
  let line = "";
  const breakStrings = [" nextflow ", " -c ", " -C ", " run ", " --", " -with", " -profile", " -resume"];
  for (let i = 0; i < command.length; i += 1) {
    for (const breakString of breakStrings) {
      if (command.substring(i).startsWith(breakString)) {
        lines.push(line);
        line = "";
      }
    }
    line += command[i];
  }
  lines.push(line);
  const finalCommand = lines.filter(s => s.trim()).map(s => `    ${s.trim()} \\`).join("\n").trim().slice(0, -2);

  const longestLineLength = Math.max(...lines.map(s => s.length));

  const fontSize = longestLineLength < 100 ? "text-xs md:text-sm" : longestLineLength < 150 ? "text-2xs md:text-xs" : "text-2xs";
  return (
    <div className={`relative leading-relaxed font-mono whitespace-pre max-w-full bg-gray-800 text-white p-4 rounded ${fontSize} md:leading-relaxed`}>
      <div className="overflow-x-auto">{finalCommand}</div>
      <div className="w-6 bg-gray-500 flex p-1 rounded items-center justify-center flex-shrink-0 cursor-pointer group absolute top-1.5 right-1.5" onClick={onClick}>
        <CopyIcon className="w-4 opacity-80 fill-white group-hover:opacity-100"/>
      </div>
    </div>
  );
};

ExecutionCommand.propTypes = {
  command: PropTypes.string.isRequired,
};

export default ExecutionCommand;