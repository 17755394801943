import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts";

const RetryExecution = props => {

  const { execution } = props;

  const [user,] = useContext(UserContext);

  if (!execution.task_finished) return null;
  if (execution.status === "OK") return null;
  if (user.username !== execution.owner.username) return null;
  if (!execution.pipeline.version_active) return null;
  if (execution.pipeline.version_private && !user.is_admin) return null;
  if (execution.retried_by) return null;
  if (!execution.uuid) return null;


  const url = `/pipelines/${execution.pipeline.id}?retry=${execution.id}`;

  return (
    <Link
      to={url}
      className="btn-primary w-fit py-0.5 px-1 relative text-xs sm:text-sm md:px-2"
    >
      Retry
    </Link>
  );
};

RetryExecution.propTypes = {
  execution: PropTypes.object.isRequired,
};

export default RetryExecution;