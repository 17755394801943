import React from "react";
import PropTypes from "prop-types";
import UserImage from "./UserImage";
import { Link } from "react-router-dom";
import { ReactComponent as SampleIcon } from "../images/sample.svg";
import { ReactComponent as FilesetIcon } from "../images/fileset.svg";
import { ReactComponent as ProjectIcon } from "../images/project.svg";
import { ReactComponent as GenomeIcon } from "../images/genome.svg";
import { ReactComponent as NextflowIcon } from "../images/nf.svg";
import { ReactComponent as PipelineIcon } from "../images/pipeline.svg";
import { ReactComponent as GroupIcon } from "../images/group.svg";
import { ReactComponent as LinkIcon } from "../images/link.svg";
import { Tooltip } from "react-tooltip";
import moment from "moment";

const ObjectAttributes = props => {

  const { object } = props;

  const textClass = "whitespace-nowrap"

  return (
    <div className={`font-medium text-xs border-y py-4 border-[#F0F0F0] flex flex-wrap gap-x-2 gap-y-3 mt-3 items-center sm:gap-x-4 sm:mt-4 sm:flex-row sm:text-sm md:gap-x-6 lg:gap-x-7 ${props.className || ""}`}>
      {object.long_name && (
        <div className={textClass}>{object.long_name}</div>
      )}
      {object.owner && (
        <Link className="flex items-center font-medium gap-1" to={`/users/${object.owner.username}/`}>
          <UserImage user={object.owner} className="w-5 h-5" />
          <div className={textClass}>{object.owner.name}</div>
        </Link>
      )}
      {object.group_owner && (
        <Link className="flex items-center font-medium gap-1" to={`/groups/@${object.group_owner.slug}/`}>
          <GroupIcon className="w-5 h-5 opacity-60" />
          <div className={textClass}>{object.group_owner.name}</div>
        </Link>
      )}
      {object.created && (
        <div className={textClass}>
          <span className="text-flow-gray-7">Created </span>
          {moment(object.created * 1000).format("D MMM YYYY")}
        </div>
      )}
      {object.data_type && (
        <div className={textClass} data-tip data-tooltip-float="true" data-tooltip-id="dataTypeTooltip">
          <span className="text-flow-gray-7">Data Type </span>
          {object.data_type.name}
        </div>
      )}
      {object.latin_name && (
        <div className={textClass}>
          <span className="text-flow-gray-7">Latin name </span>
          <span className="italic">{object.latin_name}</span>
        </div>
      )}
      {object.pipeline && (
        <Link to={`/pipelines/${object.pipeline.id}/`} className="flex items-center font-medium gap-1">
          <PipelineIcon className="w-5 h-5" />
          <div className={textClass}>{object.pipeline.name}</div>
        </Link> 
      )}
      {(object.nextflow_version || object.identifier) && (
        <div className="flex items-center font-medium gap-1">
          <NextflowIcon className="w-5 h-5" />
          {object.nextflow_version}
          {object.nextflow_version && object.identifier && <span>/</span>}
          <span className="font-mono text-xs font-semibold">{object.identifier}</span>
        </div>
      )}
      {(object.members) && (
        <div className={textClass}>
          {object.members.length}
          <span className="text-flow-gray-7"> member{object.members.length === 1 ? "" : "s"}</span>
        </div>
      )}
      {object.paired && (
        <Link to={`/data/${object.paired.id}/`} className="flex items-center font-medium gap-1">
          <span className="text-flow-gray-7">Paired with </span>
          <div className={textClass}>{object.paired.filename}</div>
        </Link>
      )}
      {object.sample && (
        <Link to={`/samples/${object.sample.id}/`} className="flex items-center font-medium gap-1">
          <SampleIcon className="w-4 h-auto" fill="" />
          <div className={textClass}>{object.sample.name}</div>
        </Link>
      )}
      {object.fileset && (
        <Link to={`/filesets/${object.fileset.id}/`} className="flex items-center font-medium gap-1">
          <FilesetIcon className="w-4 h-auto" fill="" />
          <div className={textClass}>{object.fileset.name}</div>
        </Link>
      )}
      {object.project && (
        <Link to={`/projects/${object.project.id}/`} className="flex items-center font-medium gap-1">
          <ProjectIcon className="w-4 h-auto" fill="" />
          <div className={textClass}>{object.project.name}</div>
        </Link>
      )}
      {object.genome && (
        <Link to={`/genomes/${object.genome.id}/`} className="flex items-center font-medium gap-1">
          <GenomeIcon className="w-3 h-auto" fill="" />
          <div className={textClass}>{object.genome.organism_name} {object.genome.name}</div>
        </Link>
      )}
      {object.retries && (
        <Link to={`/executions/${object.retries.id}/`} className="flex items-center font-medium gap-1">
          <span className="text-flow-gray-7">Retries </span>
          <div className={textClass}>{object.retries.identifier}</div>
        </Link>
      )}
      {object.retried_by && (
        <Link to={`/executions/${object.retried_by.id}/`} className="flex items-center font-medium gap-1">
          <span className="text-flow-gray-7">Retried by </span>
          <div className={textClass}>{object.retried_by.identifier}</div>
        </Link>
      )}
      {object.organism && (
        <Link to={`/organisms/${object.organism.id}/`} className="flex items-center font-medium gap-1">
          <GenomeIcon className="w-3 h-auto" fill="" />
          <div className={textClass}>{object.organism.name}</div>
        </Link>
      )}
      {object.data_type && (
        <Tooltip id="dataTypeTooltip" effect="float">
          <div>{object.data_type.description}</div>
        </Tooltip>
      )}
      {object.url && (
        <a href={object.url} className="flex items-center font-medium gap-1">
          <LinkIcon className="w-3 h-auto mt-0.5" fill="" />
          <div className={textClass}>{object.url.split("/")[2]}</div>
        </a>
      )}
    </div>
  );
};

ObjectAttributes.propTypes = {
  object: PropTypes.object.isRequired,
};

export default ObjectAttributes;