import React from "react";
import PropTypes from "prop-types";
import RetryExecution from "./RetryExecution";
import CancelExecution from "./CancelExecution";
import ExecutionStatus from "./ExecutionStatus";

const ExecutionCornerStatus = props => {

  const { execution } = props;

  return (
    <div className="flex gap-x-1.5 items-center">
      <RetryExecution execution={execution} />
      <CancelExecution execution={execution} />
      <ExecutionStatus execution={execution} />
    </div>
  );
};

ExecutionCornerStatus.propTypes = {
  execution: PropTypes.object.isRequired,
};

export default ExecutionCornerStatus;